import React, { useEffect, useRef } from 'react'

import { navigate } from "gatsby"

export default function EmployeeInvitation() {
    const playStoreLink = "https://play.google.com/store/apps/details?id=com.mycawan.mycawan_staff";
    const appStoreLink = "https://apps.apple.com/fr/app/netresto-mobile-app/id6462230560";

    const openedNewTabRef = useRef(false);

    useEffect(() => {
        if (!openedNewTabRef?.current) {
            setTimeout(
                () => {
                    let newWin;
                    if (navigator.userAgent.match(/iPhone/i)
                        || navigator.userAgent.match(/iPad/i)
                        || navigator.userAgent.match(/iPod/i)
                    ) {
                        newWin = window.open(appStoreLink, "_self");
                    } else if (navigator.userAgent.match(/Android/i)) {
                        newWin = window.open(playStoreLink, "_self");
                    }

                    openedNewTabRef.current = true;

                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        navigate("/mycawan_staff/")
                    }
                },
                1000
            )
        }
    });

    return (
        <></>
    )

}
